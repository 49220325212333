import { Button } from '@checkrx/pay-component-library';
import { FC } from 'react';

type Props = {
  year: string;
  onClick: () => void;
};

export const TaxesLink: FC<Props> = ({ year, onClick }) => {
  return (
    <Button
      text={`1099-NEC for ${year}`}
      width="100%"
      colorVariant="accent"
      sizeVariant="small"
      onClick={onClick}
    />
  );
};
